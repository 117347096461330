@keyframes fillbar {
    from {
      width: 0%;
    }
  
    to {
      width: 100%;
    }
}

@keyframes rotatestar {
    from {
        transform: rotate(0);
    }
    to {
        transform: rotate(360deg);
    }
}

.mood-container {
    flex-grow: 1;
    justify-content: space-between;
    align-items: center;
    max-width: 300px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    
}

.smiley-face {
    // margin-left: -15px;
    flex-basis: 10%;
    padding: 0px;
    animation-name: rotatestar;
    animation-duration: 5s;
    animation-iteration-count: infinite;
    animation-play-state: paused;
    animation-direction: alternate;
}

.smiley-face:hover {
    animation-play-state: running;
}

.mood-bar {
    flex-grow: 1;
    min-height: 30px;
    max-height: 50px;
    border-radius: 8px;
    outline: 3px solid black;
    display: flex;
}

.mood-fill {
    background-color: #F2CBCB;
    border-radius: 8px;

    animation-name: fillbar;
    animation-duration: 8s;
    animation-iteration-count: infinite;
    
    
}

