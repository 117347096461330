@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

body {
  font-family: 'Roboto Mono', sans-serif;
  font-weight: 600;
  background-color: #cddef3;
  background-image: repeating-linear-gradient(rgba(255, 255, 255, 0.5) 0 1px, transparent 1px 100%),
                    repeating-linear-gradient(90deg, rgba(255, 255, 255, 0.5) 0 1px, transparent 1px 100%);
  background-size: 41px 41px;
  height: 100%;
  -ms-overflow-style: none;
  scrollbar-width: none;
  cursor: crosshair;
 
  animation: appear 1.5s ease-in 1;
  
}

@keyframes appear {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
  
}
a {
  color: #D499A4;
  font-weight: 600;
  text-decoration: none;
  
}
a:hover {
  color: black;
}
body::-webkit-scrollbar {
  display: none;
}

.main-app {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.center-pic {
  position: relative;
  margin: auto;
  margin-top: 10%;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.star {
  display: none;
  z-index: 1;
  position: absolute;
  left: 300px;
  top: 10px;
  min-height: 100px;
  min-width: 100px;
  max-height: 200px;
  max-width: 200px;
}

.icons {
  width: 100%;
  height: 100%;
}

.about-me-icon {
  position: absolute;
  top: 175px;
  left: -180px;
  max-height: 200px;
  max-width: 200px;
  width: 200px;
  height: 200px;
  
  
}
.resume-icon {
  position: absolute;
  top: 30px;
  left: -170px;
  width: 200px;
  height: 200px;
  max-height: 200px;
  max-width: 200px;
}


.projects-icon {
  position: absolute;
  top: 30px;
  right: -135px;
  width: 200px;
  height: 200px;
  max-height: 200px;
  max-width: 200px;
}

.contact-me-icon {
  position: absolute;
  top: 195px;
  right: -140px;
  width: 200px;
  height: 200px;
  max-height: 200px;
  max-width: 200px;
}

.mood {
  position: relative;
  margin: auto;
  width: 50%;
  min-height: 50px;
  max-height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  
}
.test-modal {
  position: relative;
}

.double-list {
  display: flex;
  flex-direction: row;
  column-gap: 30px;
}

.left-list {
  display: flex;
  flex-grow: 1;
  flex-direction: column;

}

.right-list {
  display: flex;
  flex-grow: 1;
  flex-direction: column;

}


// @media screen and (max-width: 1485px) {
  

//   .resume-icon {
//     top: 550px;
//     left: 0px;
//   }

//   .about-me-icon {
//     top: 700px;
//     left: 0px
//   }
//   .projects-icon {
//     top: 550px;
//     right: 0px;
    
//   }
//   .contact-me-icon {
//     top: 700px;
//     right: 0px
//   }
// }

@media screen and (max-width: 1485px) {
  .main-app {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
  }
  .center-pic {
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 50px;
    width: 80%;
    
  }

  .center-card {
    width: 100%;
    min-height: 400px;
    max-height: 400px;
    display: flex;
  }
  .mood {
    display: none;
  }
  .icons {
    
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    row-gap: 20px;
    // min-height: 400px;
    min-width: 300px;
    // max-width: 400px;
  }
  // .icons-row {
  //   display: flex;
  //   flex-direction: row;
  //   justify-content: space-between;
  //   align-items: center;

  // }
  .resume-icon {
    position: unset;
    order: 1;
    max-width: 150px;
    max-height: 150px;
  }
  .about-me-icon {
    position: unset;
    order: 3;
    max-width: 150px;
    max-height: 150px;
  }

  .projects-icon {
    position: unset;
    order: 2;
    max-width: 150px;
    max-height: 150px;
  }

  .contact-me-icon {
    position: unset;
    order: 4;
    max-width: 125px;
    max-height: 150px;
  }

}

@media screen and (max-width: 600px) {
  .center-card {
    width: 100%;
    min-height: 300px;
    max-height: 300px;
    display: flex;
  }
}
