.label::before {
    content: " ";
    position: absolute;
    top: 100%;
    left: 50%;    
    border-width: 13px;
    border-style: dashed;
    border-color: black transparent transparent transparent;
}

.label::after {
    content: " ";
    z-index: 1;
    position: absolute;
    top: calc(100% - 1px);
    left: calc(50% + 3px);
    border-width: 10px;
    border-style: solid;
    border-color: white transparent transparent transparent;
}
.label {
    position: relative;
    max-width: 100px;
    background-color: white;
    border-radius: 8px;
    padding: 5px;
    outline: 3px solid black;
    display: flex;
    justify-content: center;
    align-content: center;
}

.label-text {
    flex-grow: 1;
    text-align: center;
}

@media screen and (max-width: 600px) {
    .label {
        outline: 0px;
        border: 3px solid black;
        border-radius: 8px;
    }
    
}

