*::-webkit-scrollbar {
    width: 5px; 
}
  
*::-webkit-scrollbar-track {
    background: white;        /* color of the tracking area */
  }
  
*::-webkit-scrollbar-thumb {
    background-color: #F2CBCB;    /* color of the scroll thumb */
    border-radius: 20px;       /* roundness of the scroll thumb */
    border: 1px solid white; /* creates padding around scroll thumb */
    
  }
* {
    scrollbar-width: thin;
    scrollbar-color: #F2CBCB;
}

.card {
    flex-grow: 1;
    background-color: white;
    outline: 3px solid black;
    border-radius: 15px;
    width: 700px;
    height: 400px;
    display: flex;
    flex-direction: column;    

}

@keyframes appear {
    0%{
      opacity: 0;
      transform: translateY(-10px);
    }
  }
 

.card-header {
    
    flex-basis: 8%;
    flex-shrink: 0;
    padding-top: 8px;
    text-align: center;
    background-color: #F2CBCB;
    border-bottom: 3px solid #D499A4;
    border-bottom-color: #D499A4;
    border-top-left-radius: 13px;
    border-top-right-radius: 13px;

}
.card-content {
    
    // flex-grow: 1;
    display: flex;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    padding: 15px;
    
}
.me-image {
    align-items: center;
    object-fit: contain;
    justify-content: center;
    margin: auto;
    max-width: 50%;
    max-height: 50%;
    border-radius: 13px;
}

.modal-content {
    flex-grow: 1;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    overflow: auto;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 20px;
    padding: 10px;
}


.modal-text {
    flex-grow: 1;
    width: 100%;
    display: flex;
    // align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
    row-gap: 15px;
    overflow-y: auto;
    overflow-x: hidden;
    
}

.section-header {
    border: 3px solid black;
    border-radius: 8px;
    background-color: #F2CBCB;
    margin: auto;
    max-width: 50%;
    text-align: center;
    padding-top: 3px;
    padding-bottom: 3px;

}

.section-text {
    font-family: 'Roboto Mono', sans-serif;
    font-weight: 400;
}

.double-list {
    display: flex;
    flex-direction: row;
    column-gap: 30px;
}

.left-list {
    display: flex;
    flex-grow: 1;
    flex-direction: column;

}

.right-list {
    display: flex;
    flex-grow: 1;
    flex-direction: column;

}
.split {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    
}
.text {
    flex-grow: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-align: justify;
    row-gap: 15px;
   
}
.image {
    flex-grow: 2;
    border-radius: 13px;

}
.me-image {
    min-width: 200px;
    max-height: 150px;
    border-radius: 13px;
}

@media screen and (max-width: 992px) {
    .card {
        width: 100%;
        height: 100%;
    }
    .image {
        display: none;
    }
    .text {
        text-align: left;
    }
    .double-list {
        display: flex;
        flex-direction: row;
        column-gap: 10px;
        word-wrap: break-word;
    }
      
    .left-list {
        display: flex;
        flex-grow: 1;
        flex-direction: column;
    
    }
    
    .right-list {
        display: flex;
        flex-grow: 1;
        flex-direction: column;
    }
}


@media screen and (max-width: 650px) {

 

    .card {
        min-width: 100% !important;
        max-height: 300px;
        outline: 0px;
        border: 3px solid black;
        border-radius: 15px;
    }
    
    
    .modal-text {
        overflow-wrap: break-word;
    }
    .double-list {
        
        display: flex;
        flex-direction: column;
        column-gap: 0px;
        row-gap: 0px;
        flex-wrap: wrap;
    }
    .left-list {
        flex-basis: 100%;
        flex-shrink: 0;
    }
    .right-list {
        flex-basis: 100%;
        
    }
    
}