@keyframes buttonhover {
    from {
      background-color: #F2CBCB;
    }
  
    to {
      background-color: #D499A4;
    }
}

.button {
    background-color: #F2CBCB;
    border-radius: 8px;
    outline: 3px solid black;
    max-width: 100px;
    max-height: 50px;
    margin-left: 10%;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
    padding: 5px;
    margin: 0;
    transition: background-color 500ms;

}

.button:hover {
    background-color: #D499A4;
}

@media screen and (max-width: 600px) {
  .button {
      outline: 0px;
      border: 3px solid black;
      border-radius: 8px;
  }
}
