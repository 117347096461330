.container {
    height: 100%;
    width: 100%;
    z-index: 1;
}

.icon {
    position: absolute;
    width: 100%;
}

.left-label {
    position: absolute;
    top: 10px;
    left: -15px;
    min-width: 50px;
    width: 100px;
}

.resume {
    position: absolute;
    top: -15px;
    left: -10px;
    min-width: 50px;
    width: 100px;
}

.about {
    position: absolute;
    top: 10px;
    left: -6px;
    min-width: 50px;
    width: 100px;
}

.contact {
    position: absolute;
    top: -30px;
    left: 104px;
    min-width: 50px;
    width: 100px;
}

.projects {
    position: absolute;
    top: -40px;
    left: 88px;
    min-width: 50px;
    width: 100px;
}

.right-label {
    position: absolute;
    top: 10px;
    right: 50px;
    min-width: 50px;
    width: 100px;
}

@media screen and (max-width: 1485px) {
    .icon {
        width: 100%;
        position: unset;
    }
    .resume {
        position: absolute;
        top: -180px;
        left: -25px;
        min-width: 50px;
        width: 100px;
    }
    
    .about {
        position: absolute;
        top: -165px;
        left: -25px;
        min-width: 50px;
        width: 100px;
    }
    
    .contact {
        position: absolute;
        top: -165px;
        left: -50px;
        min-width: 50px;
        width: 100px;
    }
    
    .projects {
        position: absolute;
        top: -180px;
        left: -25px;
        min-width: 50px;
        width: 100px;
    }
}